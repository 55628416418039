import React from 'react'
import {
  AreaSearch,
  Bicycle,
  BrainResearch,
  CoinsSwap,
  Compass,
  DocStarIn,
  Golf,
  MagicWand,
  QuoteMessage,
} from 'iconoir-react'
import {
  BookmarkIcon,
  CheckIcon,
  ClipboardDocumentListIcon,
  ComputerDesktopIcon,
  DocumentTextIcon,
  HeartIcon,
  LightBulbIcon,
  PlusIcon,
  PuzzlePieceIcon,
  SpeakerWaveIcon,
  TrashIcon,
  UserIcon,
  VideoCameraIcon,
  WrenchIcon,
  WrenchScrewdriverIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

// Map of display names to icon components
const iconMap = {
  add: PlusIcon,
  article: Compass,
  assessment: BrainResearch,
  audio: SpeakerWaveIcon,
  alya: MagicWand,
  bookmark: BookmarkIcon,
  builder: WrenchScrewdriverIcon,
  checklist: ClipboardDocumentListIcon,
  completed: CheckIcon,
  'coach-insights': DocStarIn,
  'coach-services': UserIcon,
  exercise: Bicycle,
  heart: HeartIcon,
  negotiation: CoinsSwap,
  quiz: PuzzlePieceIcon,
  'sample-doc': DocumentTextIcon,
  scripts: QuoteMessage,
  'search-tools': AreaSearch,
  'short-course': Golf,
  tips: LightBulbIcon,
  tools: WrenchIcon,
  trash: TrashIcon,
  video: VideoCameraIcon,
  webinar: ComputerDesktopIcon,
  x: XMarkIcon,
}

// Export individual icon components
export const Add = PlusIcon
export const Article = Compass
export const Assessment = BrainResearch
export const Audio = SpeakerWaveIcon
export const Alya = MagicWand
export const Bookmark = BookmarkIcon
export const Builder = WrenchScrewdriverIcon
export const Checklist = ClipboardDocumentListIcon
export const Completed = CheckIcon
export const CoachInsights = DocStarIn
export const CoachServices = UserIcon
export const Exercise = Bicycle
export const Heart = HeartIcon
export const Negotiation = CoinsSwap
export const Quiz = PuzzlePieceIcon
export const SampleDoc = DocumentTextIcon
export const Scripts = QuoteMessage
export const SearchTools = AreaSearch
export const ShortCourse = Golf
export const Tips = LightBulbIcon
export const Tools = WrenchIcon
export const Trash = TrashIcon
export const Video = VideoCameraIcon
export const Webinar = ComputerDesktopIcon
export const X = XMarkIcon

function YnsIcon({ name, className = '', size = 24, ...props }) {
  // Convert name to lowercase and handle special characters
  const normalizedName = name.toLowerCase().replace(/\s+/g, '-')
  const IconComponent = iconMap[normalizedName]

  if (!IconComponent) {
    console.warn(`Icon "${name}" not found in icon map`)
    return null
  }

  // Check if className already includes width/height classes
  const hasWidthClass = /\bw-\d+\b/.test(className)
  const hasHeightClass = /\bh-\d+\b/.test(className)

  // Add size classes only if they're not already present
  const sizeClasses =
    !hasWidthClass && !hasHeightClass ? `w-[${size}px] h-[${size}px]` : ''
  const finalClassName = `${className} ${sizeClasses}`.trim()

  return <IconComponent className={finalClassName} {...props} />
}

export default YnsIcon
