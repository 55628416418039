import React from 'react'
import { Button } from '../../lib/ui/button'
import LinkedInIcon from '../job_search/JobSearchPage/LinkedInIcon'
import LeaveSiteWarning from './LeaveSiteWarning/index'
import { cn } from '~/lib/utils'

export const ClearSelectionsButton = ({ className, children, ...props }) => {
  return (
    <Button variant="outline" size="sm" {...props}>
      {children}
    </Button>
  )
}

export const LinkedInButton = ({ companyName, className, children }) => {
  const linkedInURL = `https://www.linkedin.com/search/results/people?company=${companyName}&facetNetwork=%5B%22F%22%2C%22S%22%5D&origin=FACETED_SEARCH`
  return (
    <LeaveSiteWarning url={linkedInURL}>
      <Button
        type="button"
        variant="outline"
        className={cn(
          'flex flex-row justify-center gap-3 px-6 data-[compact=true]:gap-2 data-[compact=true]:px-4 sm:px-8',
          className,
        )}
      >
        {children}
        <LinkedInIcon size="20" />
      </Button>
    </LeaveSiteWarning>
  )
}

export const FilterButton = ({ className, active, children, ...props }) => {
  return (
    <Button
      {...props}
      className={cn(
        'block flex-none border border-alto-300 border-input bg-background bg-intoo-haze px-4 py-2 font-roboto text-sm text-muted-foreground visited:outline-none visited:ring-ring visited:ring-offset-background hover:bg-accent hover:text-accent-foreground focus:ring-ring focus:ring-offset-background active:bg-accent active:text-accent-foreground active:outline-none',
        active && 'bg-intoo-blue-medium-100 font-bold',
        className,
      )}
    >
      {children}
    </Button>
  )
}
