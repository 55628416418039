import * as React from 'react'

import { useFilters } from './useFilters'
import { useJobSearchQuery } from './useJobSearchQuery'
import { useJobSearchParams } from './useJobSearchParams'
import { useJobSearchForm } from './useJobSearchForm'
import useTypingAnimator from './useTypingAnimator'
import { useWalkthrough } from './WalkthroughProvider'

const JobSearchContext = React.createContext()

export function useJobSearch() {
  return React.useContext(JobSearchContext)
}

export function JobSearchProvider({
  job_boards,
  search_results,
  total_results,
  total_pages,
  next_page,
  saved_params,
  allow_debug,
  isIntooItaly,
  isDemo,
  keySkills,
  showTalentguide,
  availableResumes,
  children,
  runWalkthrough,
  testing,
  radii,
}) {
  const [selectedJobDrawerOpen, setSelectedJobDrawerOpen] =
    React.useState(false)
  const resultDetailScrollRef = React.useRef(null)
  const [selectedJob, setSelectedJob] = React.useState(null)
  const [noResultQuery, setNoResultQuery] = React.useState('')
  const [viewMoreButtonDisabled, setViewMoreButtonDisabled] =
    React.useState(false)
  const [formDisabled, setFormDisabled] = React.useState(false)
  const {
    state: { started, stepIndex },
  } = useWalkthrough()

  const whatVal = useTypingAnimator(started, 'logistics manager')
  const whereVal = useTypingAnimator(stepIndex === 1, 'Dallas, TX')
  const { urlSearchParams, setUrlSearchParams, paramString, lockParamString } =
    useJobSearchParams({ whatVal, whereVal, runWalkthrough, testing })
  const [savedJobIds, setSavedJobIds] = React.useState([])
  const debug = allow_debug && urlSearchParams.has('debug')
  const [previousFirstJobId, setPreviousFirstJobId] = React.useState(null)
  const [showFeedbackDialog, setShowFeedbackDialog] = React.useState(false)
  const toggleShowFeedbackDialog = () => setShowFeedbackDialog((prev) => !prev)

  const {
    queryStatusMessage,
    pagesToShow,
    setPagesToShow,
    setPrefetchedPages,
    jobBoards,
    jobResults,
    companyList,
    amountShown,
    amountTotal,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isPending,
    showViewMoreButton,
  } = useJobSearchQuery({
    paramString,
    initialData: {
      pageParams: [1],
      pages: [
        {
          job_boards: job_boards,
          results: search_results,
          total_results: total_results,
          total_pages: total_pages,
          next_page: next_page,
        },
      ],
    },
  })

  const { form, onSubmit } = useJobSearchForm({
    urlSearchParams,
    lockParamString,
    noResultQuery,
    setPagesToShow,
    setPrefetchedPages,
    saved_params,
    whatVal,
    whereVal,
  })

  const { hasFilters, resetFilters, sort, setSort } = useFilters({
    form,
    setUrlSearchParams,
    run: runWalkthrough || testing,
  })

  // Disable form when fetching
  // but only when we have only one page to show
  // and we are not fetching the next page in the background
  React.useEffect(() => {
    if (isFetching && pagesToShow === 1 && !isFetchingNextPage) {
      setFormDisabled(true)
    } else {
      setFormDisabled(false)
    }
  }, [pagesToShow, isFetching, isFetchingNextPage, isPending])

  // Set selected job when job results are updated
  // but only if the first job has changed
  const firstJob = jobResults[0]
  React.useEffect(() => {
    if (firstJob && firstJob.id !== previousFirstJobId) {
      setPreviousFirstJobId(firstJob.id)
      setSelectedJob(firstJob)
    }
  }, [firstJob, previousFirstJobId])

  const urlSearchParamsWhat = urlSearchParams.get('what')
  React.useEffect(() => {
    if (jobResults.length === 0) {
      setNoResultQuery(urlSearchParamsWhat)
    } else {
      setNoResultQuery('')
    }
  }, [jobResults.length, urlSearchParamsWhat, paramString])

  const handleSelectJob = (job) => (event) => {
    event.stopPropagation()
    resultDetailScrollRef.current?.scrollTo(0, 0, { behavior: 'smooth' })
    setSelectedJob(job)
    setSelectedJobDrawerOpen(true)
  }

  const handleViewMore = async () => {
    setViewMoreButtonDisabled(true)
    setPagesToShow((prev) => prev + 1)
    await fetchNextPage()
    setViewMoreButtonDisabled(false)
  }

  const value = {
    form,
    jobBoards,
    formDisabled,
    onSubmit,
    setSelectedJob,
    selectedJob,
    hasFilters,
    resetFilters,
    selectedJobDrawerOpen,
    setSelectedJobDrawerOpen,
    resultDetailScrollRef,
    noResultQuery,
    handleSelectJob,
    handleViewMore,
    urlSearchParams,
    queryStatusMessage,
    setNoResultQuery,
    jobResults,
    amountShown,
    amountTotal,
    viewMoreButtonDisabled,
    showViewMoreButton,
    savedJobIds,
    setSavedJobIds,
    debug,
    isIntooItaly,
    isDemo,
    keySkills,
    showTalentguide,
    availableResumes,
    sort,
    setSort,
    runWalkthrough,
    testing,
    whatVal,
    whereVal,
    showFeedbackDialog,
    toggleShowFeedbackDialog,
    radii,
    companyList,
  }

  return (
    <JobSearchContext.Provider value={value}>
      {children}
    </JobSearchContext.Provider>
  )
}
