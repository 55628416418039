import React from 'react'
import Results from './Results'
import Avatar from '../Avatar'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useTranslation } from '../../../hooks/useTranslation'
import { useCalculateMultipleChoiceScore } from '../../JobSearchQuiz/useScoreCalculator'
import { useQuiz } from '../QuizProvider'

const StressManagementTacticsResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent, quizName } =
    useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()
  const { t } = useTranslation(formattedQuizName)

  const [score, numCorrect] = useCalculateMultipleChoiceScore(
    trackContent,
    t('questions'),
  )
  const numQuestions = Object.keys(trackContent).length
  return (
    quizName === 'Stress Management Tactics' && (
      <Results>
        <div className="flex flex-grow overflow-y-auto pb-6 sm:justify-evenly">
          <div className="mb-24 mt-1 flex h-96 flex-col gap-4 sm:w-8/12">
            <h2>{t(`results.score`, { numCorrect, numQuestions, score })}</h2>
            <div className="mt-4">
              <p>{t(`results.congratulations`)}</p>
              <p>{t(`results.all`)}</p>
              <p>{t(`results.all_p2`)}</p>
            </div>
          </div>
          {isDesktop && <Avatar src={avatar} />}
        </div>
      </Results>
    )
  )
}

export default StressManagementTacticsResults
