import React from 'react'
import RetirementGoalsPageOne from './RetirementGoalsPageOne'
import RetirementGoalsPageTwo from './RetirementGoalsPageTwo'
import RetirementGoalsPageThree from './RetirementGoalsPageThree'

const RetirementGoalsExercise = ({ section, trackContent }) => {
  return (
    <div>
      {section === 0 && <RetirementGoalsPageOne data={trackContent} />}
      {section === 1 && <RetirementGoalsPageTwo data={trackContent} />}
      {section === 2 && <RetirementGoalsPageThree data={trackContent} />}
    </div>
  )
}

export default RetirementGoalsExercise
