import React from 'react'
import { useTranslation } from '~/hooks/useTranslation'
import { useQuiz } from '../QuizProvider'
import parse from 'html-react-parser'
import { PrimaryQuizButton } from '../Buttons'
import { PuzzlePieceIcon } from '@heroicons/react/24/outline'

const StartQuizTop = ({ quizExists }) => {
  const { t } = useTranslation('')
  const { setStarted, saveQuiz, formattedQuizName } = useQuiz()
  const title = t(`${formattedQuizName}.start_quiz.title`)
  const description = t(`${formattedQuizName}.start_quiz.description`)
  const instructions = t(`${formattedQuizName}.start_quiz.instructions`)
  const buttonText = quizExists ? t('shared.continue') : t('shared.start')
  const showInstructions = typeof instructions === 'object'
  const showDescription = typeof description === 'object'

  const handleStartQuizClick = () => {
    if (quizExists) {
      setStarted(true)
    } else {
      saveQuiz()
    }
  }

  return (
    <div className="flex flex-col bg-[#E0F2F9] p-0 px-4 md:gap-4 md:px-8 md:pb-12">
      <div className="flex w-full flex-col items-center justify-between gap-4 md:flex-row">
        <h1 className="mb-0 mt-10 !font-roboto-slab text-2xl text-[32px] font-medium leading-normal text-cloud-burst">
          {title}
        </h1>
        <div className="mb-2 flex md:mb-0 md:mt-10">
          <PrimaryQuizButton
            className="my-8 h-12 rounded-[20px] bg-primary hover:bg-primary-darker-75 sm:h-10"
            onClick={handleStartQuizClick}
          >
            <div className="flex items-center gap-2">
              {buttonText}
              <PuzzlePieceIcon className="size-6 text-white" />
            </div>
          </PrimaryQuizButton>
        </div>
      </div>
      {showInstructions && (
        <div className="flex flex-col text-intoo-blue-medium">
          {instructions.map((instruction, idx) => (
            <div className="subtitle" key={`instruction-${idx}`}>
              {parse(instruction)}
            </div>
          ))}
        </div>
      )}
      {showDescription && (
        <div className="flex flex-col gap-2 text-intoo-blue-medium">
          {description.map((desc, idx) => (
            <div key={`description-p-${idx}`}>{parse(desc)}</div>
          ))}
        </div>
      )}
    </div>
  )
}
export default StartQuizTop
