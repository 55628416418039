import React from 'react'
import { useTranslation } from '../../hooks/useTranslation'
import { useQuiz } from './QuizProvider'
import Outcomes from './StartQuiz/Outcomes'
import StartQuizTop from './StartQuiz/StartQuizTop'
import Sections from './StartQuiz/Sections'

const StartQuiz = ({ questionCount, quizExists }) => {
  const { formattedQuizName, gifPath } = useQuiz()
  const { t } = useTranslation('')
  const outcomes = t(`${formattedQuizName}.start_quiz.outcomes`)
  const questionsInfo = t(`${formattedQuizName}.questions`)
  const questionsArr = Object.values(questionsInfo)
  const numQuestions = questionCount || questionsArr.length

  const showOutcomes = typeof outcomes === 'object'
  return (
    <>
      <StartQuizTop quizExists={quizExists} />
      <div className="flex flex-col items-start justify-between p-8 md:flex-row md:divide-x md:divide-[#e5e5e5]">
        <div>
          {showOutcomes && <Outcomes outcomes={outcomes} />}
          <Sections numQuestions={numQuestions} />
        </div>
        <div className="flex justify-center">
          <img className="ml-16 h-96" src={gifPath} />
        </div>
      </div>
    </>
  )
}
export default StartQuiz
