import React, { useState } from 'react'
import { cn } from '../../lib/utils'
import Moment from 'moment'
import { useTranslation } from '../../hooks/useTranslation'
import { Button, Typography } from '~/components/_atoms'
import { Stack } from '~/components/_layouts'
import ReminderDialog, { useReminderDialog } from './ReminderDialog'
import {
  PencilSquareIcon,
  CheckBadgeIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import { apiPatch } from '../../util/api'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../lib/ui/dialog'
import { FlagIcon } from './Icons'

const PromoteSkillDialog = ({
  open,
  onOpenChange,
  handleCompleteReminder,
  skillName,
  children,
}) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild className="cursor-pointer">
        {children}
      </DialogTrigger>
      <DialogContent
        className="site-info-dialog roboto max-w-xl"
        aria-describedby={undefined}
      >
        <DialogHeader>
          <DialogTitle className="font-roboto-slab text-xl tracking-[0.12px]">
            Promote Skill
          </DialogTitle>
        </DialogHeader>
        <div className="px-4 pb-4">
          <p>
            {`Would you like to add ${skillName} to your skills inventory?`}
          </p>
          <div className="flex flex-row justify-between pt-4">
            <Button onClick={handleCompleteReminder}>Complete</Button>
            <Button
              onClick={() => handleCompleteReminder({ promoteSkill: true })}
            >
              Complete and Add to Skills Inventory
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const Reminders = ({ className }) => {
  const { reminders, toggleShowAddReminderDialog } = useReminderDialog()
  const { t } = useTranslation()

  return (
    <Stack className={className} spacing={3}>
      <Typography>{t('reminders.description')}</Typography>
      <ReminderDialog>
        <Button
          variant="outline"
          startIcon={<PlusIcon />}
          onClick={toggleShowAddReminderDialog}
        >
          {t('reminders.new_action_text')}
        </Button>
      </ReminderDialog>
      {reminders.map((reminder, idx) => (
        <ReminderTile
          key={`reminders-${idx}`}
          reminder={reminder}
        ></ReminderTile>
      ))}
    </Stack>
  )
}
export default Reminders

const ReminderTile = ({ reminder }) => {
  const { setReminders, setEditingReminder, setShowAddReminderDialog } =
    useReminderDialog()
  const [reminderCompleted, setReminderCompleted] = useState(false)
  const [showPromoteSkillDialog, setShowPromoteSkillDialog] = useState(false)
  const [border, setBorder] = useState('border')
  const [transitionDuration, setTransitionDuration] = useState('duration-2000')
  const [divHeight, setDivHeight] = useState('max-h-full min-h-48')
  const reminderAnimation = reminderCompleted
    ? 'animate__animated animate__fadeOut'
    : ''
  const background = reminderCompleted
    ? 'bg-intoo-blue-medium bg-opacity-95'
    : 'bg-white'

  const dueDate = reminder.due_date
  const content = reminder.content
  const skillName = reminder.skill_name
  const documentId = reminder.document_id
  const linkedRecordPath = reminder.linked_record_path
  const linkedRecordText = reminder.linked_record_text
  const mayPromoteSkill = !!(skillName && documentId)
  const isPastDue = Moment(dueDate).isSameOrBefore(Moment(), 'day')
  const webinarLink = reminder.webinar_link

  const togglePromoteSkillDialog = () => {
    setShowPromoteSkillDialog(!showPromoteSkillDialog)
  }

  const removeReminderFromList = (completedReminder) => {
    setReminders((prev) => prev.filter((r) => r.id !== completedReminder.id))
  }

  const completedReminderAnimation = (completedReminder) => {
    setReminderCompleted(true)
    setTimeout(() => {
      setBorder('')
      setDivHeight('h-0 min-h-0')
      setTransitionDuration('duration-200')
    }, 2000)
    setTimeout(() => {
      removeReminderFromList(completedReminder)
      setReminderCompleted(false)
      setDivHeight('max-h-full min-h-48')
      setTransitionDuration('')
      setBorder('border')
    }, 2200)
    setTimeout(() => {
      setTransitionDuration('duration-2000')
    }, 2550)
  }

  const handleCompleteReminder = async ({ promoteSkill = false }) => {
    const { id } = reminder
    const data = { reminder: { promote_skill: promoteSkill } }

    setShowPromoteSkillDialog(false)

    const res = await apiPatch(`/reminders/${id}/complete`, data)
    if (res.status === 200) {
      completedReminderAnimation(reminder)
      return res
    } else {
      console.log(res)
      console.log(res.status)
      console.log(res.error)
      throw new Error('res not ok')
    }
  }

  const handleEditReminder = () => {
    setEditingReminder(reminder)
    setShowAddReminderDialog(true)
  }

  const convertFromNow = () => {
    return Moment(dueDate).calendar(null, {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
    })
  }

  const todayOrTomorrow = convertFromNow()

  const due = dueDate
    ? ['Today', 'Tomorrow'].includes(todayOrTomorrow)
      ? todayOrTomorrow
      : Moment(dueDate).format('M/D/YY')
    : 'Not Set'

  const borderColor = isPastDue
    ? 'border-[#E10000]'
    : ['Tomorrow'].includes(due)
      ? 'border-[#FF990059]'
      : 'border-[#D6D6D6]'

  return (
    <div
      id={`reminder-${reminder.id}`}
      className={cn(
        'flex-flex-col overflow-hidden rounded-[20px] border-solid bg-white shadow-dashboard-reminder-tile',
        borderColor,
        reminderAnimation,
        background,
        transitionDuration,
        divHeight,
        border,
      )}
    >
      <>
        {reminderCompleted && (
          <div className="relative top-12 flex w-full justify-center">
            <CheckBadgeIcon className="h-20 text-white" />
          </div>
        )}
        {!reminderCompleted && (
          <>
            <div className="flex items-center justify-between p-4">
              <div className={isPastDue ? 'flex text-[#E10000]' : ''}>
                Due Date: {due} {isPastDue && <FlagIcon className="ml-2" />}
              </div>
              <PencilSquareIcon
                className="h-6 cursor-pointer"
                onClick={handleEditReminder}
              />
            </div>
            <hr />
            <div className="relative flex min-h-32 flex-col justify-between gap-2">
              <div className="p-4">
                {content}{' '}
                {webinarLink && (
                  <LinkifiedCopy copy={'Join Now'} link={webinarLink} />
                )}
                {linkedRecordPath && (
                  <a href={linkedRecordPath} className="!underline">
                    {linkedRecordText}
                  </a>
                )}
              </div>
              <div className="self-end p-4">
                {mayPromoteSkill ? (
                  <PromoteSkillDialog
                    open={showPromoteSkillDialog}
                    onOpenChange={togglePromoteSkillDialog}
                    handleCompleteReminder={handleCompleteReminder}
                    skillName={skillName}
                  >
                    <Button onClick={togglePromoteSkillDialog}>Complete</Button>
                  </PromoteSkillDialog>
                ) : (
                  <Button onClick={handleCompleteReminder}>Complete</Button>
                )}
              </div>
            </div>
          </>
        )}
      </>
    </div>
  )
}

const LinkifiedCopy = ({ copy, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="!underline"
    >
      {copy}
    </a>
  )
}
