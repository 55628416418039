import React, { useState, useEffect, useMemo } from 'react'
import { InputField, SelectField } from '../../shared/FormFields'
import { Form } from '../../../lib/ui/form'
import { useForm } from 'react-hook-form'
import { Button } from '../../../lib/ui/button'
import InsightCard from './InsightCard'
import { zodResolver } from '@hookform/resolvers/zod'
import { TranslationProvider } from '../../../components/shared/TranslationProvider'
import { useTranslation } from '../../../hooks/useTranslation'
import translations from '../locales.json'
import { z } from 'zod'

const CoachInsights = ({ coach_insights }) => {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const [category, setCategory] = useState('')
  const [visibleCount, setVisibleCount] = useState(3)
  const coachInsights = useMemo(
    () => JSON.parse(coach_insights),
    [coach_insights],
  )
  const [filteredInsights, setFilteredInsights] = useState([])
  console.log(filteredInsights)

  const schema = z.object({
    search: z.string().optional(),
    category: z.string().optional(),
  })

  const form = useForm({
    defaultValues: {
      search: '',
      category: '',
    },
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    const filtered = coachInsights.filter((insight) => {
      const matchesSearchTerm =
        searchTerm === '' ||
        insight.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        insight.content.toLowerCase().includes(searchTerm.toLowerCase())

      const matchesCategory =
        category === t('categories.all_categories') ||
        category === '' ||
        insight.category.toLowerCase() === category.toLowerCase()

      return matchesSearchTerm && matchesCategory
    })
    setFilteredInsights(filtered)
  }, [coachInsights, searchTerm, category, t])

  const categories = [
    t('categories.all_categories'),
    ...new Set(coachInsights.map((insight) => insight.category).sort()),
  ]

  const handleSearch = (data) => {
    setSearchTerm(data.search || '')
    setCategory(data.category || '')
    setVisibleCount(3) // Reset on new search
  }

  return (
    <div className="mr-20">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSearch)}>
          <div className="mb-6 flex w-full flex-col justify-between gap-4">
            <div className="flex w-full flex-col items-start gap-8 md:flex-row md:items-end">
              <InputField
                name="search"
                control={form.control}
                label={t('search_insights')}
                className="w-full md:w-1/2"
              />
              <SelectField
                name="category"
                control={form.control}
                label={t('category')}
                choices={categories.map((choice) => ({
                  value: choice,
                  label: t(
                    `categories.${choice.toLowerCase().replace(/\s+/g, '_')}`,
                  ),
                }))}
                className="w-full md:w-1/2"
              />
              <Button variant="coachcta" type="submit">
                {t('search')}
              </Button>
            </div>
          </div>
        </form>
      </Form>

      <div className="mt-12 flex flex-col gap-12">
        {filteredInsights.slice(0, visibleCount).map((insight) => (
          <InsightCard insight={insight} key={insight.id} />
        ))}
      </div>

      {visibleCount < filteredInsights.length && (
        <div className="my-6">
          <button
            onClick={() => setVisibleCount((prev) => prev + 3)}
            className="text-sm text-intoo-green-coachcta hover:underline"
          >
            {t('view_more')}
          </button>
        </div>
      )}
    </div>
  )
}

const Index = ({ coach_insights, locale }) => {
  return (
    <TranslationProvider
      translations={translations}
      locale={locale}
      scope="javascript.components.coach_services.CoachInsights"
    >
      <CoachInsights coach_insights={coach_insights} />
    </TranslationProvider>
  )
}

export default Index
