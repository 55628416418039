import * as React from 'react'

import { JobSearchProvider } from './JobSearchProvider'
import ToolBar from './ToolBar'
import SearchResults from './SearchResults'
import translations from '../locales.json'
import { TranslationProvider } from '../../shared/TranslationProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SavedJobsProvider } from '../SavedJobsPage/SavedJobsProvider'
import { BrowserRouter } from 'react-router-dom'
import JobSearchWalkthrough from './JobSearchWalkthrough'
import { WalkthroughStateProvider } from './WalkthroughProvider'
const queryClient = new QueryClient()

export default function JobSearchPage({
  job_boards,
  search_results,
  total_results,
  total_pages,
  next_page,
  saved_params,
  allow_debug,
  locale,
  isDemo,
  isIntooItaly,
  showTalentguide,
  keySkills,
  availableResumes,
  walkthroughEnabled,
  runWalkthrough,
  testing,
  radii,
}) {
  return (
    <QueryClientProvider client={queryClient}>
      <TranslationProvider
        locale={locale}
        translations={translations}
        scope="javascript.components.job_search.JobSearchPage"
      >
        <BrowserRouter>
          <SavedJobsProvider saved_jobs={{}}>
            <WalkthroughStateProvider>
              <JobSearchProvider
                job_boards={job_boards}
                search_results={search_results}
                total_pages={total_pages}
                total_results={total_results}
                next_page={next_page}
                saved_params={saved_params}
                allow_debug={allow_debug}
                isIntooItaly={isIntooItaly}
                showTalentguide={showTalentguide}
                isDemo={isDemo}
                keySkills={keySkills}
                availableResumes={availableResumes}
                walkthroughEnabled={walkthroughEnabled}
                runWalkthrough={runWalkthrough}
                testing={testing}
                radii={radii}
              >
                <style>
                  {`
            // TODO: fix and remove this

            .fix-legacy-hack p {
              margin: 0 !important;
            }

            .fix-legacy-hack ul {
              margin: 0 !important;
              padding-inline-start: 0 !important;
            }

            .fix-legacy-hack h2,
            .fix-legacy-hack h3 {
              margin: 0 !important;
            }

          `}
                </style>
                {walkthroughEnabled && <JobSearchWalkthrough />}
                <div className="fix-legacy-hack flex flex-col border-l border-alto-200 pl-2 font-roboto">
                  <div className="my-10 ml-4 mr-6 flex flex-col gap-4">
                    <ToolBar />

                    <SearchResults />
                  </div>
                </div>
              </JobSearchProvider>
            </WalkthroughStateProvider>
          </SavedJobsProvider>
        </BrowserRouter>
      </TranslationProvider>
    </QueryClientProvider>
  )
}
