import React from 'react'
import { useExercise } from './ExerciseProvider'
import { Textarea } from '../../lib/ui/textarea'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../lib/ui/select'

const IdentifyYourGoalsExercise = ({ questions, conclusion }) => {
  const { handleChange, getResponse, updateResponse, exerciseIndex } =
    useExercise()
  return (
    <div>
      <div className="questions">
        {questions.map((question, index) => (
          <div
            key={index}
            className="mb-8 w-full font-roboto text-base font-medium"
          >
            <div className="flex w-full flex-col">
              <h2 className="!mb-1 italic">{question.category}</h2>
              <label className="!mb-1">{question.question}</label>
              {question.type === 'text' && (
                <Textarea
                  className="min-h-[125px] w-full rounded-[20px]"
                  onChange={(e) =>
                    handleChange(exerciseIndex, question.slug, e)
                  }
                  value={getResponse(exerciseIndex, question.slug) || ''}
                />
              )}
              {question.type === 'select' && (
                <Select
                  onValueChange={(e) =>
                    updateResponse(exerciseIndex, question.slug, e)
                  }
                  value={getResponse(exerciseIndex, question.slug) || ''}
                  className="w-full rounded-[20px] border border-[#D3D3D3] bg-[#F7FAFC] p-3 font-roboto text-base"
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select an option" />
                  </SelectTrigger>
                  <SelectContent>
                    {question.choices.map((choice, index) => (
                      <SelectItem key={index} value={choice}>
                        {choice}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6 font-roboto text-lg">{conclusion}</div>
    </div>
  )
}

export default IdentifyYourGoalsExercise
