import * as React from 'react'
import { useAlerts } from './AlertsProvider'
import useTranslation from '../../shared/TranslationProvider'

export function ViewJobsCell({ row }) {
  const { fetchAlertJobCount, createTransitionNotification } = useAlerts()
  const [numJobs, setNumJobs] = React.useState()
  const alert = row.original
  const { what, where, employment_type, job_type, employment_level } = alert
  const { t } = useTranslation('DataTable')

  React.useEffect(() => {
    const getJobCount = () => {
      fetchAlertJobCount(alert)
        .then((res) => {
          setNumJobs(res.data.job_count)
          return res
        })
        .catch((err) => {
          console.log(err)
          return err
        })
    }
    getJobCount()
  }, [alert, fetchAlertJobCount])

  const viewJobsText = numJobs
    ? numJobs === 1
      ? t('view_job')
      : t('view_num_jobs', { numJobs })
    : t('view_jobs')

  const whatParam = what ? `what=${what}` : ''

  const whereParam = where ? `where=${where}` : ''

  const getParams = (types, filterStr) => {
    let str = ''
    if (typeof types === 'string') {
      return `${filterStr}=${types}`
    }
    types.forEach((type) => {
      str += `${filterStr}=${type}&`
    })
    return str.slice(0, -1) // Remove the trailing '&'
  }

  // onsite, remote, hybrid
  const employmentTypeParam = employment_type
    ? getParams(employment_type, 'filter_employment_type[]')
    : ''

  // full_time, part_time, contract, internship
  const jobTypeParam = job_type ? getParams(job_type, 'filter_job_type[]') : ''

  // internship, entrylevel, midseniorlevel, manager, director, executive
  const employmentLevelParam = employment_level
    ? getParams(employment_level, 'filter_experience_level[]')
    : ''

  const query = [
    whatParam,
    whereParam,
    employmentTypeParam,
    jobTypeParam,
    employmentLevelParam,
  ]
    .filter((param) => param)
    .join('&')

  const url = query ? `/job-search/jobs?${query}` : `/job-search/jobs`

  console.log('query', query)
  return (
    <a
      onClick={() => createTransitionNotification('viewedResults')}
      title="view jobs"
      href={url}
    >
      {viewJobsText}
    </a>
  )
}
