import * as React from 'react'

import { useSavedJobs } from './SavedJobsProvider'
import { useTranslation } from '../../../hooks/useTranslation'
import { Button } from '../../../lib/ui/button'
import { FilterButton } from '~/components/shared/Buttons'

const filterValues = [
  'saved',
  'applied',
  'interview',
  'offer',
  'closed',
  'archive',
]

export function Filters() {
  const { t } = useTranslation('filter')
  const {
    sortBy: { selectedFiltersRef, setColumnFilters },
  } = useSavedJobs()

  const clearFilters = () => {
    setColumnFilters([
      {
        id: 'status',
        value: ['saved', 'applied', 'interview', 'offer', 'closed'],
      },
    ])
    selectedFiltersRef.current = []
  }
  return (
    <div className="flex items-center gap-4">
      {filterValues.map((filterValue) => (
        <Filter key={filterValue} filterValue={filterValue} />
      ))}
      <Button onClick={clearFilters} variant="link">
        {t('clear')}
      </Button>
    </div>
  )
}

const Filter = ({ filterValue }) => {
  const { t } = useTranslation('filter')
  const {
    sortBy: { setColumnFilters, selectedFiltersRef },
  } = useSavedJobs()

  const selectedFilters = selectedFiltersRef.current

  const active = selectedFiltersRef.current.includes(filterValue)

  const addFilter = () => {
    if (!selectedFilters.includes(filterValue)) {
      setColumnFilters((prev) => {
        const existingFilter = prev.find((filter) => filter.id === 'status')
        if (selectedFilters.length > 0) {
          const newFilters = [...existingFilter.value, filterValue]
          selectedFiltersRef.current = newFilters
          return [{ id: 'status', value: newFilters }]
        } else {
          const newFilters = [filterValue]
          selectedFiltersRef.current = newFilters
          return [{ id: 'status', value: newFilters }]
        }
      })
    }
  }

  const removeFilter = () => {
    setColumnFilters((prev) => {
      const existingFilter = prev.find((filter) => filter.id === 'status')
      const newFilters = existingFilter.value.filter((f) => f !== filterValue)
      selectedFiltersRef.current = newFilters

      return [
        {
          id: 'status',
          value:
            newFilters.length > 0
              ? newFilters
              : ['saved', 'applied', 'interview', 'offer', 'closed'],
        },
      ]
    })
  }

  const handleFilterClick = () => {
    if (active) {
      removeFilter()
    } else addFilter()
  }

  return (
    <FilterButton active={active} onClick={handleFilterClick}>
      {t(filterValue)}
    </FilterButton>
  )
}
