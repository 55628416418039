import React from 'react'
import { cn } from '../../../lib/utils'
import {
  ExerciseIcon,
  BuilderIcon,
  ShortCourseIcon,
  SampleIcon,
  ArticleIcon,
  SearchIcon,
  CollectionIcon,
  ScriptsIcon,
  QuizIcon,
  AudioIcon,
  WebinarIcon,
} from './icons'
import parse from 'html-react-parser'
import { BrainResearch, MagicWand } from 'iconoir-react'
import { DocumentTextIcon } from '@heroicons/react/24/outline'
export const iconMap = {
  builder: <BuilderIcon />,
  sample: <SampleIcon />,
  workshop: <ShortCourseIcon />,
  short_course: <ShortCourseIcon />,
  article: <ArticleIcon />,
  exercise: <ExerciseIcon />,
  search: <SearchIcon />,
  collection: <CollectionIcon />,
  script: <ScriptsIcon />,
  quiz: <QuizIcon />,
  assessment: <BrainResearch />,
  webinar: <WebinarIcon />,
  audio: <AudioIcon />,
  sample_docs: <DocumentTextIcon />,
  ayla: <MagicWand />,
}
import { Button } from '../../../lib/ui/button'
import NewCoverLetterModal from '../../CoverLetterBuilder/NewCoverLetterModal'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useTranslation } from '../../../hooks/useTranslation'

const CtaContent = ({
  path,
  ctaClasses,
  ctaOptions,
  cta,
  cta2,
  completed,
  retakePath,
  variant,
}) => {
  const { t } = useTranslation()
  if (path.match('cover_letters/new_ai_or_builder')) {
    return <NewCoverLetterModal />
  } else {
    return (
      <>
        <a
          href={path}
          className={ctaClasses}
          target={ctaOptions && ctaOptions['target']}
          rel={ctaOptions && ctaOptions['rel']}
          title={ctaOptions && ctaOptions['title']}
        >
          <Button variant={completed ? 'completed' : variant}>
            {completed ? 'View Results' : cta}
          </Button>
        </a>
        {cta2?.length > 0 ? parse(cta2) : ''}
        {completed && (
          <a
            href={retakePath || path + '?retake=true'}
            title={`Retake ${cta}`}
            className="hover:!underline focus:!underline active:!underline"
          >
            {t('retake')} {cta.split(' ').slice(-1)[0]}
          </a>
        )}
      </>
    )
  }
}

const ExerciseCard = ({ exercise, className }) => {
  const isDesktop = useIsDesktop()
  const {
    headerImageUrl,
    path,
    title,
    description,
    cta,
    cta2,
    type,
    ctaClasses,
    ctaOptions,
    linkCta,
    completed,
    retakePath,
    coachServices,
    coachName,
    showCoachName,
    coachImageClasses,
    meetsMinimumProgramLevel,
  } = exercise

  const icon = Object.prototype.hasOwnProperty.call(iconMap, type) ? (
    iconMap[type]
  ) : (
    <ExerciseIcon />
  )

  const classes = coachServices
    ? 'bg-willow-brook text-intoo-green-coachcta min-h-[16rem]'
    : 'bg-lily-blue text-primary'

  const iconClasses = coachServices
    ? 'stroke-intoo-green-coachcta'
    : 'stroke-primary'

  return (
    (meetsMinimumProgramLevel || meetsMinimumProgramLevel === undefined) && (
      <div
        className={cn('flex justify-between rounded-2.5xl', classes, className)}
      >
        <div className="self-center p-6 md:col-span-2">
          <div className="mb-4 flex flex-wrap items-center gap-4">
            {React.cloneElement(icon, {
              className: cn('mb-2 h-10 w-10 stroke-2', iconClasses),
            })}
            <h2
              role="heading"
              tabIndex="0"
              aria-level="2"
              className="!my-2 font-roboto-slab text-xl font-bold focus:overflow-visible"
              title={title}
            >
              {title}
            </h2>
          </div>

          <p className="!mb-4 font-roboto-slab">{description}</p>
          {linkCta && (
            <div className="!mb-4 flex flex-row items-center">
              {parse(linkCta)}
            </div>
          )}
          <div className="flex flex-row items-center gap-4 self-center">
            <CtaContent
              path={path}
              ctaClasses={ctaClasses}
              ctaOptions={ctaOptions}
              cta={cta}
              cta2={cta2}
              completed={completed}
              retakePath={retakePath}
              variant={coachServices ? 'coachcta' : 'default'}
            />
          </div>
        </div>
        {isDesktop && (
          <>
            {coachServices && (
              <CoachPhoto coachName={coachName} showCoachName={showCoachName}>
                <CardImage
                  headerImageUrl={headerImageUrl}
                  className={cn(`h-full`, coachImageClasses)}
                />
              </CoachPhoto>
            )}
            {!coachServices && <CardImage headerImageUrl={headerImageUrl} />}
          </>
        )}
      </div>
    )
  )
}

export default ExerciseCard

const CardImage = ({ headerImageUrl, className }) => {
  return (
    <div
      className={cn(
        `overflow-none tw-hidden visible rounded-r-2.5xl bg-cover bg-center bg-no-repeat sm:block md:min-w-[255px]`,
        className,
      )}
      style={{ backgroundImage: `url(${headerImageUrl})` }}
    ></div>
  )
}

const CoachPhoto = ({ coachName, showCoachName, children }) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col items-center">
      {showCoachName && (
        <h2 className="mt-2 font-roboto-slab text-xl">
          {t('coach')} {coachName}
        </h2>
      )}
      {children}
    </div>
  )
}
