import React from 'react'
import { Button } from '../../../lib/ui/button'
import parse from 'html-react-parser'
import { cn } from '../../../lib/utils'

const DocumentIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="stroke-current"
    >
      <path
        d="M4 21.4V2.6C4 2.26863 4.26863 2 4.6 2H16.2515C16.4106 2 16.5632 2.06321 16.6757 2.17574L19.8243 5.32426C19.9368 5.43679 20 5.5894 20 5.74853V21.4C20 21.7314 19.7314 22 19.4 22H4.6C4.26863 22 4 21.7314 4 21.4Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6349 10.4151L11.674 8.21211C11.8073 7.9293 12.1927 7.9293 12.326 8.21211L13.3651 10.4151L15.6886 10.7706C15.9867 10.8162 16.1055 11.1997 15.8897 11.4198L14.2087 13.1334L14.6054 15.5543C14.6564 15.8652 14.3446 16.1022 14.0778 15.9554L12 14.8118L9.92219 15.9554C9.65544 16.1022 9.34363 15.8652 9.39458 15.5543L9.7913 13.1334L8.1103 11.4198C7.89447 11.1997 8.01329 10.8162 8.31141 10.7706L10.6349 10.4151Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 2V5.4C16 5.73137 16.2686 6 16.6 6H20"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const AudioIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="stroke-current"
    >
      <path
        d="M19.114 5.63589C19.9497 6.47162 20.6127 7.46377 21.065 8.55571C21.5173 9.64765 21.7501 10.818 21.7501 11.9999C21.7501 13.1818 21.5173 14.3521 21.065 15.4441C20.6127 16.536 19.9497 17.5282 19.114 18.3639M16.463 8.28789C17.4474 9.27243 18.0004 10.6077 18.0004 11.9999C18.0004 13.3921 17.4474 14.7273 16.463 15.7119M6.75 8.24989L11.47 3.52989C11.5749 3.42513 11.7085 3.35381 11.8539 3.32492C11.9993 3.29604 12.15 3.31089 12.2869 3.3676C12.4239 3.42431 12.541 3.52034 12.6234 3.64356C12.7058 3.76677 12.7499 3.91165 12.75 4.05989V19.9399C12.7499 20.0881 12.7058 20.233 12.6234 20.3562C12.541 20.4794 12.4239 20.5755 12.2869 20.6322C12.15 20.6889 11.9993 20.7037 11.8539 20.6749C11.7085 20.646 11.5749 20.5746 11.47 20.4699L6.75 15.7499H4.51C3.63 15.7499 2.806 15.2429 2.572 14.3959C2.35751 13.6153 2.2492 12.8094 2.25 11.9999C2.25 11.1699 2.362 10.3669 2.572 9.60389C2.806 8.75589 3.63 8.24989 4.51 8.24989H6.75Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const InsightCard = ({ insight }) => {
  const [listenPressed, setListenPressed] = React.useState(false)
  return (
    <div className="flex gap-4">
      <div
        style={{ backgroundImage: `url(${insight.image})` }}
        className="overflow-none tw-hidden visible w-full rounded-2xl bg-cover bg-center bg-no-repeat sm:block md:min-w-[315px] md:max-w-[315px]"
      ></div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-4">
          <div className="font-medium text-intoo-green-coachcta">
            {insight.category}
          </div>
          <div className="text-center">{insight.author}</div>
          <div className="text-4xl leading-3">&bull;</div>
          <div>{insight.date}</div>
        </div>
        <div className="subtitle">{insight.title}</div>
        <p>{insight.summary && parse(insight.summary)}</p>
        <div className="flex gap-3">
          <a href={insight.link}>
            <Button
              variant="coachcta"
              size="sm"
              className="flex items-center gap-2"
            >
              Read Insight
              <span role="img" aria-label="book">
                <DocumentIcon />
              </span>
            </Button>
          </a>
          {insight.audio && (
            <Button
              variant="outline"
              size="sm"
              className={cn(
                'flex items-center gap-2 border-intoo-green-coachcta text-intoo-green-coachcta',
                listenPressed
                  ? 'bg-intoo-green-coachcta text-white'
                  : 'border-intoo-green-coachcta text-intoo-green-coachcta',
              )}
              onClick={() => {
                setListenPressed(!listenPressed)
              }}
            >
              Listen
              <span role="img" aria-label="speaker">
                <AudioIcon />
              </span>
            </Button>
          )}
          {listenPressed && (
            <audio className="h-9 w-full" controls preload="metadata" autoPlay>
              <source src={insight.audio} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          )}
        </div>
      </div>
    </div>
  )
}

export default InsightCard
