import React from 'react'
import { Button } from '../../lib/ui/button'
import { SpeakerWaveIcon } from '@heroicons/react/24/outline'
import { cn } from '../../lib/utils'

const CoachInsightAudioPlayer = ({ audioUrl }) => {
  const [listenPressed, setListenPressed] = React.useState(false)

  return (
    <div className="flex flex-col gap-3">
      <div>
        <Button
          variant={listenPressed ? 'coachcta' : 'outline'}
          className={cn(
            'flex gap-4',
            listenPressed
              ? 'bg-intoo-green-coachcta text-white'
              : 'border-intoo-green-coachcta text-intoo-green-coachcta',
          )}
          onClick={() => {
            setListenPressed(!listenPressed)
          }}
        >
          Listen
          <SpeakerWaveIcon className="h-6 w-6" />
        </Button>
      </div>
      {listenPressed && (
        <div className="flex justify-between gap-6">
          <audio className="w-full" controls preload="metadata" autoPlay>
            <source src={audioUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )}
    </div>
  )
}

export default CoachInsightAudioPlayer
