import React from 'react'
import {
  Bookmark,
  Speaker,
  Highlighter,
  CommentBubble,
  BackArrow,
} from './icons'
import { useArticle } from './ArticleProvider'
import { Progress } from '../../lib/ui/progress'

export const NavHeader = () => {
  const { cliftonStrengths, missingAudio } = useArticle()

  return (
    <div className="sticky top-0 z-[1020] flex w-full flex-row border-b border-[#4056D4] bg-[#EAF4FF]">
      <div className="flex h-20 flex-grow flex-col items-center justify-center">
        <div className="flex max-w-5xl flex-row gap-1">
          {!cliftonStrengths && <Favorite />}
          {!cliftonStrengths && !missingAudio && <Audio />}
          <Highlight />
          <Comment />
        </div>
      </div>
      {cliftonStrengths ? (
        <BackToGuidesCliftonStrengths />
      ) : (
        <BackToResources />
      )}
    </div>
  )
}

export const AudioPlayer = () => {
  const { audioAwsUrl, audioActive } = useArticle()

  if (!audioAwsUrl || !audioActive) {
    return
  }

  return (
    <div className="w-full">
      <audio
        className="w-full"
        controls
        src={audioAwsUrl}
        autoPlay={audioActive}
      />
    </div>
  )
}

export const RightRail = () => {
  return (
    <div className="tw-hidden sticky left-0 top-20 ml-4 w-[359px] flex-shrink-0 border-l p-4 lg:block">
      <div className="sticky left-0 top-20 h-max">
        <div className="flex flex-col gap-4 bg-white">
          <ProgressDisplay />
          <TableOfContents />
        </div>
      </div>
    </div>
  )
}

const BackToResources = () => {
  const { navigateToResourceCenter } = useArticle()
  return (
    <div className="tw-hidden h-20 w-[359px] content-center pr-8 text-end lg:block">
      <button onClick={navigateToResourceCenter}>
        Back to Resource Center
        <BackArrow className="mb-1 ml-2 inline stroke-black" />
      </button>
    </div>
  )
}

const Favorite = () => {
  const { isFavorite, toggleFavorite } = useArticle()
  return (
    <ToolButton
      icon={<Bookmark active={isFavorite} />}
      onClick={toggleFavorite}
    >
      {isFavorite ? 'Favorited' : 'Favorite'}
    </ToolButton>
  )
}

const Audio = () => {
  const { audioActive, toggleAudioActive } = useArticle()

  const handleClick = () => {
    toggleAudioActive()
  }
  return (
    <ToolButton
      active={audioActive}
      icon={<Speaker active={audioActive} />}
      onClick={handleClick}
    >
      Audio
    </ToolButton>
  )
}

const Highlight = () => {
  const { toggleHighlightMode, isHighlightModeActive } = useArticle()
  return (
    <ToolButton
      icon={<Highlighter active={isHighlightModeActive} />}
      active={isHighlightModeActive}
      onClick={toggleHighlightMode}
    >
      {isHighlightModeActive ? 'Highlighting' : 'Highlight'}
    </ToolButton>
  )
}

const Comment = () => {
  const { isCommentModeActive, toggleCommentMode } = useArticle()

  return (
    <ToolButton
      active={isCommentModeActive}
      icon={<CommentBubble active={isCommentModeActive} />}
      onClick={toggleCommentMode}
    >
      Add Notes
    </ToolButton>
  )
}

const ToolButton = ({ icon, onClick, children, active = false }) => {
  let className =
    'flex flex-col items-center justify-center cursor-pointer px-4'
  if (active) {
    className += ' bg-primary text-white rounded-2xl py-2 font-medium'
  }

  return (
    <button className={className} onClick={onClick}>
      {icon}
      <span className="pt-2">{children}</span>
    </button>
  )
}

const ProgressDisplay = () => {
  const { maxScrollDepth } = useArticle()

  return (
    <div className="flex flex-col items-center pr-4 pt-6">
      <Progress
        className="bg-[#BEE1FF]"
        indicatorClassName="bg-gradient-to-r from-[#08467C] to-[#4CACE8]"
        value={maxScrollDepth}
        aria-labelledby="progress-number-display"
      />
      <div id="progress-number-display" className="mt-2">
        {maxScrollDepth}% completed
      </div>
    </div>
  )
}

const TableOfContents = () => {
  const {
    jsonContent: { sections },
    jumpToSection,
  } = useArticle()
  return (
    <>
      <div className="font-bold">Table of Contents</div>
      <ol className="list-outside list-decimal space-y-2 pl-4">
        {sections.map((section, index) => {
          if (!section.exclude_from_toc) {
            return (
              <li
                className="cursor-pointer text-balance break-words hover:text-primary hover:underline"
                key={index}
                onClick={() => jumpToSection(index)}
              >
                {section.tocTitle || section.title}
              </li>
            )
          }
        })}
      </ol>
    </>
  )
}

const BackToGuidesCliftonStrengths = () => {
  const { navigateToGuides } = useArticle()
  return (
    <div className="!visible float-right mr-8 hidden h-20 place-content-center sm:!block">
      <button onClick={navigateToGuides}>
        Back to Guides
        <BackArrow className="mb-1 ml-2 inline stroke-black" />
      </button>
    </div>
  )
}
