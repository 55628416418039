import * as React from 'react'

import { format } from 'date-fns'
import { useTranslation } from '../../../hooks/useTranslation'
import { FollowupCell } from './FollowupCell'
import { StatusCell } from './StatusCell'
import { TitleCell } from './TitleCell'
import { DebugInfo } from '../../shared/DebugInfo'

export const columns = [
  {
    accessorKey: 'title',
    header: (props) => <TableHeader {...props} />,
    cell: (props) => <TitleCell {...props} />,
  },
  {
    accessorKey: 'created_at',
    header: (props) => <TableHeader {...props} />,
    cell: (props) => <CreatedAtCell {...props} />,
    sortingFn: 'datetime',
  },
  {
    accessorKey: 'updated_at',
    header: (props) => <TableHeader {...props} />,
    cell: (props) => <CreatedAtCell {...props} />,
    sortingFn: 'datetime',
  },
  {
    accessorKey: 'followup_on',
    header: (props) => <TableHeader {...props} />,
    cell: (props) => <FollowupCell {...props} />,
    sortingFn: 'datetime',
    sortUndefined: 1,
  },
  {
    accessorKey: 'status',
    header: (props) => <TableHeader {...props} />,
    cell: (props) => <StatusCell {...props} />,
    filterFn: (row, _columnId, filterValue) => {
      if (Array.isArray(filterValue)) {
        return filterValue.includes(row.original.status)
      }
      return true
    },
    sortingFn: 'statusSortingFn',
  },
]

function TableHeader({ column }) {
  const debug = false
  const handleClick = (e) => {
    if (!debug) return

    const handleSorting = column.getToggleSortingHandler()
    handleSorting(e)
  }
  const { t } = useTranslation('TableHeader')

  return <div onClick={handleClick}>{t(column.id)}</div>
}

function CreatedAtCell({ row, getValue }) {
  const date = getValue()
  const formattedDate = format(new Date(date), 'P')
  const debug = false

  return (
    <DebugInfo obj={row.original} debug={debug}>
      <div className="w-[112px] pl-2 text-base">{formattedDate}</div>
    </DebugInfo>
  )
}
