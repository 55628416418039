import * as React from 'react'
import { useCompanySearch } from './CompanySearchProvider'
import { useTranslation } from '../../../hooks/useTranslation'
import { useIsMobile } from '../../../hooks/useMediaQuery'
import {
  DetailsBottom,
  DetailsBottomHeading,
  DetailsDetails,
  DetailsRow,
  DetailsTop,
  DetailsTopBody,
  DetailsTopSave,
  DetailsTopSubtitle,
  DetailsTopTitle,
} from '~/components/shared/SearchResultDetails'
import LeaveSiteWarning from '~/components/shared/LeaveSiteWarning/index'
import { Button } from '~/lib/ui/button'
import { LinkedInButton } from '~/components/shared/Buttons'
import SearchResultsSavedStatus from '~/components/shared/SearchResultsSavedStatus'
import { CheckIcon } from '~/components/job_search/SavedJobsPage/Icons'

export function CompanyDetailsTop() {
  const {
    selectedCompany: company,
    debug,
    queryStatusMessage,
    isSavedCompany,
  } = useCompanySearch()
  const isMobile = useIsMobile()
  if (!company) return null
  const { name, addressLine1, addressLine2, city, state, postalCode } = company

  return (
    <DetailsTop>
      {!isMobile && (
        <DetailsTopSave>
          {isSavedCompany(company) ? <SavedStatus /> : <AddCompanyButton />}
        </DetailsTopSave>
      )}

      <DetailsTopBody>
        <DetailsTopTitle>{name}</DetailsTopTitle>

        <DetailsTopSubtitle>
          <div className="flex flex-col">
            <div>{addressLine1}</div>
            {addressLine2 && <div>{addressLine2}</div>}
            <div>
              {city}, {state}
            </div>
            <div>{postalCode}</div>
          </div>
        </DetailsTopSubtitle>
      </DetailsTopBody>

      {debug && <pre className="break-all text-xs">{queryStatusMessage}</pre>}
    </DetailsTop>
  )
}

export function CompanyDetailsBottom() {
  const { selectedCompany: company } = useCompanySearch()
  const { t } = useTranslation('CompanyDetails')

  const headingClasses = '!mr-16 w-40'

  if (!company) return null

  const {
    name,
    companySize,
    yearFounded,
    legalStatus,
    phone,
    website,
    fax,
    contacts,
    formattedLocation,
    mapSrc,
    competitors,
    parentCompanyName,
    ownership,
    industries,
  } = company

  const linkedInUrl = `https://www.linkedin.com/search/results/companies/?keywords=${name}`

  return (
    <DetailsBottom>
      <div className="flex flex-col gap-10">
        <CompanyDetailsSubSection sectionName="company_information">
          <div className="flex flex-col gap-4">
            <DetailsRow>
              <DetailsBottomHeading className={headingClasses}>
                {t('company_size')}
              </DetailsBottomHeading>
              <DetailsDetails>
                {companySize || t('info_unavailable')}
              </DetailsDetails>
            </DetailsRow>
            {yearFounded && (
              <DetailsRow>
                <DetailsBottomHeading className={headingClasses}>
                  {t('year_founded')}
                </DetailsBottomHeading>
                <DetailsDetails>{yearFounded}</DetailsDetails>
              </DetailsRow>
            )}
            {!!industries.length && (
              <DetailsRow>
                <DetailsBottomHeading className={headingClasses}>
                  {t('industries')}
                </DetailsBottomHeading>
                <DetailsDetails>{industries.join(', ')}</DetailsDetails>
              </DetailsRow>
            )}
            {parentCompanyName && (
              <DetailsRow>
                <DetailsBottomHeading className={headingClasses}>
                  {t('parent_company_name')}
                </DetailsBottomHeading>
                <DetailsDetails>{parentCompanyName}</DetailsDetails>
              </DetailsRow>
            )}
            {legalStatus && (
              <DetailsRow>
                <DetailsBottomHeading className={headingClasses}>
                  {t('legal_status')}
                </DetailsBottomHeading>
                <DetailsDetails>{legalStatus}</DetailsDetails>
              </DetailsRow>
            )}
            {ownership && (
              <DetailsRow>
                <DetailsBottomHeading className={headingClasses}>
                  {t('ownership')}
                </DetailsBottomHeading>
                <DetailsDetails>{ownership}</DetailsDetails>
              </DetailsRow>
            )}
            <DetailsRow>
              <DetailsBottomHeading className={headingClasses}>
                {t('linkedin')}
              </DetailsBottomHeading>
              <DetailsDetails>
                <LeaveSiteWarning url={linkedInUrl}>
                  <Button variant="link">{name}</Button>
                </LeaveSiteWarning>
              </DetailsDetails>
            </DetailsRow>
            <DetailsRow>
              <DetailsDetails>
                <LinkedInButton companyName={name}>
                  <div>{t('see_connections')}</div>
                </LinkedInButton>
              </DetailsDetails>
            </DetailsRow>
          </div>
        </CompanyDetailsSubSection>
        {competitors && (
          <CompanyDetailsSubSection sectionName="competitors">
            <div>
              {competitors.map((competitor, idx) => (
                <CompetitorRow
                  key={`competitor-${idx}`}
                  competitor={competitor}
                />
              ))}
            </div>
          </CompanyDetailsSubSection>
        )}
        <CompanyDetailsSubSection sectionName="contact_information">
          <div className="flex flex-col gap-4">
            <DetailsRow>
              <DetailsBottomHeading className={headingClasses}>
                {t('phone')}
              </DetailsBottomHeading>
              <DetailsDetails>{phone || t('info_unavailable')}</DetailsDetails>
            </DetailsRow>
            {fax && (
              <DetailsRow>
                <DetailsBottomHeading className={headingClasses}>
                  {t('fax')}
                </DetailsBottomHeading>
                <DetailsDetails>{fax}</DetailsDetails>
              </DetailsRow>
            )}
            {website && (
              <DetailsRow>
                <DetailsBottomHeading className={headingClasses}>
                  {t('website')}
                </DetailsBottomHeading>
                <DetailsDetails>
                  <LeaveSiteWarning url={website}>
                    <Button variant="link">{website}</Button>
                  </LeaveSiteWarning>
                </DetailsDetails>
              </DetailsRow>
            )}
            {formattedLocation && (
              <DetailsRow>
                <DetailsBottomHeading className={headingClasses}>
                  {t('map_directions')}
                </DetailsBottomHeading>
                <DetailsDetails>
                  <div className="flex flex-col gap-4">
                    <iframe
                      width="240"
                      height="240"
                      style={{ border: '0', borderRadius: '10px' }}
                      loading="lazy"
                      allowFullScreen
                      src={mapSrc}
                    ></iframe>
                    <LeaveSiteWarning
                      url={`https://maps.google.com/?q=${formattedLocation}`}
                    >
                      <Button variant="outline">{t('directions')}</Button>
                    </LeaveSiteWarning>
                  </div>
                </DetailsDetails>
              </DetailsRow>
            )}
          </div>
        </CompanyDetailsSubSection>
        <CompanyDetailsSubSection sectionName="contacts">
          <div>
            {contacts &&
              contacts.map((contact) => (
                <ContactRow key={`contact-${contact.id}`} contact={contact} />
              ))}
          </div>
          {!contacts && <DetailsRow>{t('no_contacts')}</DetailsRow>}
        </CompanyDetailsSubSection>
      </div>
    </DetailsBottom>
  )
}

const CompanyDetailsSubSection = ({ sectionName, children }) => {
  const { t } = useTranslation('CompanyDetails')
  return (
    <div className="flex flex-col gap-6">
      <div className="subtitle">{t(sectionName)}</div>
      {children}
    </div>
  )
}

const CompetitorRow = ({ competitor }) => {
  const { goToCompetitor } = useCompanySearch()
  const { name } = competitor
  return (
    <DetailsRow className={'gap-4'}>
      <DetailsBottomHeading>
        <Button variant="link" onClick={() => goToCompetitor(competitor)}>
          {name}
        </Button>
      </DetailsBottomHeading>
    </DetailsRow>
  )
}

const ContactRow = ({ contact }) => {
  const { isSavedContact } = useCompanySearch()
  const { firstName, lastName, title, contactPath, email } = contact

  return (
    <div className={'flex flex-wrap items-center justify-between pt-4'}>
      <div>
        <span>
          <a href={contactPath}>
            {firstName} {lastName}
          </a>
        </span>
        {title && ` - ${title}`}
        {email && (
          <span>
            {' - '}
            <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
              {email}
            </a>
          </span>
        )}
      </div>
      {isSavedContact(contact) ? (
        <SavedContact />
      ) : (
        <AddContactButton contact={contact} />
      )}
    </div>
  )
}

const AddCompanyButton = () => {
  const { t } = useTranslation('CompanyDetails')
  const { selectedCompany: company, addCompany } = useCompanySearch()
  const { contacts } = company
  const contact = contacts && contacts[0]

  return (
    <Button
      className="flex rounded-full border border-white bg-transparent p-2 text-base font-medium text-white"
      type="button"
      onClick={() => addCompany(company, contact)}
    >
      + {t('add_company')}
    </Button>
  )
}

const SavedStatus = () => {
  const { t } = useTranslation('CompanyDetails')

  return (
    <SearchResultsSavedStatus title={t('saved_to_my_companies')}>
      <div className="text-sm font-medium text-white">
        {t('saved_to')}{' '}
        <span className="text-sm font-medium text-white">
          <a
            className="text-white !underline"
            href="/network-and-research/companies"
          >
            {t('my_companies')}
          </a>
        </span>
      </div>
    </SearchResultsSavedStatus>
  )
}

const SavedContact = () => {
  const { t } = useTranslation('CompanyDetails')
  return (
    <div className="flex items-center gap-4 px-8 py-2">
      <CheckIcon />
      <div className="text-sm font-medium">{t('added')}</div>
    </div>
  )
}

const AddContactButton = ({ contact }) => {
  const { t } = useTranslation('CompanyDetails')
  const { addCompany, selectedCompany } = useCompanySearch()
  const handleAddContact = () => {
    addCompany(selectedCompany, contact)
  }
  return (
    <Button onClick={handleAddContact} variant="link">
      + {t('add_contact')}
    </Button>
  )
}
