import React, { useEffect } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { useExercise } from '../ExerciseProvider'
import { ReturnToResourceCenter, DownloadExerciseButton } from '../Buttons'
import {
  CalendarIcon,
  ConclusionPageImage,
  ExerciseIcon,
  PersonIcon,
} from '../icons'
import { useIsMobile } from '../../../hooks/useMediaQuery'

const RetirementGoalsConclusion = () => {
  const {
    formattedExerciseName,
    documentId,
    dateAndTimeUpdated,
    candidateFirstName,
    ssoName,
  } = useExercise()
  const { t } = useTranslation(formattedExerciseName)

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  const link = {
    linkPath: `/documents/${documentId}.pdf`,
    linkText: t('conclusion_link'),
  }

  return (
    <div className="exercise mb-0">
      <Header
        t={t}
        ssoName={ssoName}
        candidateFirstName={candidateFirstName}
        dateAndTimeUpdated={dateAndTimeUpdated}
      />
      <SummaryContent link={link} t={t} />
    </div>
  )
}

const Header = ({ t, ssoName, candidateFirstName, dateAndTimeUpdated }) => {
  const isMobile = useIsMobile()
  const { t: tExercise } = useTranslation('exercise')
  return (
    <div className="bg-intoo-blue-dark text-white">
      <div
        className={`${
          isMobile ? 'mx-4' : 'mx-8'
        } flex items-center justify-between ${isMobile ? 'pb-10' : 'pb-14'}`}
      >
        <div>
          <h1 className="mb-0 mt-10 !font-roboto-slab text-2xl text-[32px] font-medium leading-normal !text-white">
            {t(`start_exercise.title`)}
          </h1>
          <hr className="!b-0 my-4 h-[1px] w-32 bg-white text-white" />
          <div className="flex flex-col !font-roboto-slab text-[20px] sm:flex-row">
            {!ssoName && (
              <div
                className={`${
                  isMobile ? 'mb-4' : ''
                } mr-0 flex items-center sm:mr-12`}
              >
                <div className="mr-4">
                  <PersonIcon />
                </div>
                {`${candidateFirstName}'s`} {tExercise('results')}
              </div>
            )}
            <div className="flex items-center">
              <div className="mr-4">
                <CalendarIcon />
              </div>
              {dateAndTimeUpdated}
            </div>
          </div>
        </div>
        <div className="mt-10 flex">
          <ExerciseIcon />
        </div>
      </div>
    </div>
  )
}

const SummaryContent = ({ link, t }) => {
  const isMobile = useIsMobile()
  const { retakeExercise, documentId } = useExercise()
  const { t: tExercise } = useTranslation('exercise')
  const conclusionGreatJob = t('conclusion_great_job')
  const goalPlannerLink = {
    linkPath: `/exercise/retirement_goals_planner/${documentId}.pdf`,
    linkText: t('goal_planner_link'),
  }

  return (
    <div
      className={`summary-content ${
        isMobile ? 'p-6' : 'p-10'
      } flex flex-col bg-[#E0F2F9]`}
    >
      <div
        className={`rounded-[20px] bg-white ${
          isMobile ? 'px-4 py-12' : 'px-12 py-12'
        }`}
      >
        <div className="text-center">
          <h2 className="mb-4 text-2xl font-semibold">
            <LinkifiedCopy copy={conclusionGreatJob} link={goalPlannerLink} />
          </h2>
          <DisplayGoalPlanner />
          <div className="mx-auto my-6 flex justify-center">
            <ConclusionPageImage />
          </div>
          <p className="mb-4">{t('dont_forget')}</p>
          <DisplayAllInterests />
        </div>
        <div className="flex flex-col">
          <div className="flex justify-center">
            <DownloadExerciseButton
              href={link.linkPath}
              text={t('conclusion_link')}
            />
          </div>
          <div className="flex justify-center">
            <a
              onClick={retakeExercise}
              className="mt-4 cursor-pointer !underline"
            >
              {tExercise('retake_exercise')}
            </a>
          </div>
        </div>
      </div>
      <ReturnToResourceCenter />
    </div>
  )
}

const LinkifiedCopy = ({ copy, link }) => {
  const [copy1, copy2] = copy.split('[link]')
  const { linkPath, linkText } = link
  return (
    <p className="font-roboto text-lg font-medium">
      {copy1}
      {copy2 && (
        <a
          href={linkPath}
          target="_blank"
          rel="noopener noreferrer"
          className="!underline"
        >
          {linkText}
        </a>
      )}
      {copy2}
    </p>
  )
}

const DisplayGoalPlanner = () => {
  const { trackContent, formattedExerciseName } = useExercise()
  const { t } = useTranslation(formattedExerciseName)

  const actionPlan = trackContent[2]?.actionPlan || []

  return (
    <div className="goal-planner mx-auto flex max-w-[650px] flex-col gap-4 text-left">
      {actionPlan.map((plan, index) => (
        <div
          key={index}
          className="w-full rounded-[8px] border border-[#D3D3D3] bg-[#F7FAFC] p-3"
        >
          <h3 className="mb-2 text-center text-xl font-semibold">{`Interest: ${plan.interest}`}</h3>
          <div className="actions mb-2">
            <h4 className="font-medium">{t('actions')}</h4>
            <ul className="ml-7 list-disc">
              {plan.actions.map((action, idx) => (
                <li key={idx}>
                  <span className="font-medium">{action.action}</span>
                  <br />
                  <span className="italic">
                    {t('timeline')} {action.timeline || t('no_timeline')}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="barriers mb-2">
            <strong>{t('barriers')}</strong> {plan.barriers || t('none')}
          </div>
          <div className="people-to-share mb-2">
            <strong>{t('people_to_share_with')}</strong>{' '}
            {plan.peopleToShareWith || 'None'}
          </div>
        </div>
      ))}
    </div>
  )
}

const DisplayAllInterests = () => {
  const { trackContent } = useExercise()
  const allInterests = [
    ...(trackContent[0]?.goodAt || []),
    ...(trackContent[0]?.interestedIn || []),
    ...(trackContent[0]?.triedAndEnjoyed || []),
  ]
  const selectedInterests = trackContent[1]?.topInterests || []
  const remainingInterests = allInterests.filter(
    (interest) => !selectedInterests.includes(interest),
  )

  return (
    <div className="goal-planner mx-auto flex max-w-[650px] flex-col gap-4 text-left">
      {remainingInterests.map((interest, index) => (
        <div
          key={index}
          className="w-full rounded-[8px] border border-[#D3D3D3] bg-[#F7FAFC] p-3"
        >
          <p className="!mb-0 text-center font-semibold">{interest}</p>
        </div>
      ))}
    </div>
  )
}
export default RetirementGoalsConclusion
