import React, { useState, useEffect } from 'react'
import LeaveSiteWarning from '../../shared/LeaveSiteWarning'
import { useTranslation } from '../../../hooks/useTranslation'
import { getApi } from '~/util/api'
import { Button, Card, Typography } from '~/components/_atoms'
import { Stack } from '~/components/_layouts'
import Spinner from '~/components/shared/Spinner'

export function RecommendedJobsCard({ className }) {
  const { t } = useTranslation()
  let companyColors = ['#A93226', '#633974', '#1A5276']
  let companyColorMap = {}
  let colorIndex = 0
  const [isLoading, setIsLoading] = useState(false)
  const [recommendedJobs, setRecommendedJobs] = useState([])

  useEffect(() => {
    setIsLoading(true)
    getApi()
      .get('/recommended_jobs')
      .then((response) => {
        if (response.status == 200) {
          setRecommendedJobs(response.data)
          return
        } else {
          throw new Error('json request failed')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
      .catch((error) => {
        console.log('json request failed', error)
      })
  }, [setRecommendedJobs])

  return (
    <Card className={className} title={t('recommended_jobs_card.title')}>
      <Stack spacing={3}>
        {isLoading ? (
          <div className="flex items-center justify-center">
            <Spinner show size={9} />
          </div>
        ) : (
          <Stack spacing={6}>
            {recommendedJobs.map((job, index) => {
              let companyColor = companyColorMap[job['company']]
              if (!companyColor) {
                companyColor = companyColors[colorIndex % companyColors.length]
                companyColorMap[job['company']] = companyColor
                colorIndex++
              }

              return (
                <>
                  {index > 0 && <hr key={`divider-${index}`} />}
                  <div
                    key={`item-${index}`}
                    className="flex flex-col gap-3 @sm:flex-row @sm:items-center @sm:justify-between"
                  >
                    <div className="flex gap-3">
                      {job['logo'] ? (
                        <div>
                          <img
                            src={job['logo']}
                            alt={job['company']}
                            className="h-12 w-12 rounded-sm object-cover"
                          />
                        </div>
                      ) : (
                        <div className="col-span-2">
                          <div
                            className="flex h-12 w-12 flex-grow items-center justify-center rounded-sm text-2xl text-white"
                            style={{ backgroundColor: companyColor }}
                          >
                            {job['company'][0].toUpperCase()}
                          </div>
                        </div>
                      )}
                      <Stack>
                        <Typography className="font-bold">
                          {job['title']}
                        </Typography>
                        <Typography>
                          {job['company']} - {job['location']}
                        </Typography>
                      </Stack>
                    </div>
                    <div>
                      <LeaveSiteWarning url={job['url']}>
                        <Button variant="outline">
                          {t('recommended_jobs_card.view_action')}
                        </Button>
                      </LeaveSiteWarning>
                    </div>
                  </div>
                </>
              )
            })}
          </Stack>
        )}
      </Stack>
    </Card>
  )
}
