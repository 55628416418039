import React from 'react'
import { TranslationProvider } from '../shared/TranslationProvider'
import translations from './locales.json'
import { ExerciseProvider, useExercise } from './ExerciseProvider'
import Exercise from './Exercise'
import StartExercise from './StartExercise'
import Conclusion from './Conclusion'
import NetworkingPreferencesConclusion from './NetworkingPreferences/NetworkingPreferencesConclusion'
import SocialMediaConclusion from './SocialMediaReview/SocialMediaConclusion'
import RetirementGoalsConclusion from './RetirementGoals/RetirementGoalsConclusion'

const Index = ({ ...props }) => {
  return (
    <ExerciseProvider {...props}>
      <ExerciseComponents {...props} />
    </ExerciseProvider>
  )
}

export default Index

const ExerciseComponents = ({ locale }) => {
  const { documentId, started, showConclusion, exerciseName, embedExercise } =
    useExercise()

  return (
    <TranslationProvider
      locale={locale || 'en'}
      translations={translations}
      scope={'javascript.components.Exercises'}
    >
      {exerciseName === 'Networking Preferences' && showConclusion ? (
        <NetworkingPreferencesConclusion />
      ) : exerciseName === 'Social Media Review' && showConclusion ? (
        <SocialMediaConclusion />
      ) : exerciseName === 'Retirement Goals' && showConclusion ? (
        <RetirementGoalsConclusion />
      ) : (
        showConclusion && <Conclusion />
      )}
      {!embedExercise && !started && !showConclusion && (
        <StartExercise exerciseExists={!!documentId} />
      )}
      {(started || embedExercise) && !showConclusion && <Exercise />}
    </TranslationProvider>
  )
}
