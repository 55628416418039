import * as React from 'react'

import { useCookies } from 'react-cookie'

const dateSortByList = ['created_at', 'updated_at', 'followup_on']

export const useSortBy = () => {
  const selectedFiltersRef = React.useRef([])
  const [sortByValue, setSortByValue] = useCookieState(
    'sortByValue',
    'followup_on',
  )

  const [pinFollowupJobs, setPinFollowupJobs] = useCookieState(
    'pinFollowupJobs',
    true,
  )

  const [dateSortByValue, setDateSortByValue] = useCookieState(
    'dateSortByValue',
    'followup_on',
  )

  const [columnFilters, setColumnFilters] = React.useState([
    {
      id: 'status',
      value: ['saved', 'applied', 'interview', 'offer', 'closed'],
    },
  ])

  const [selectedFilters, setSelectedFilters] = React.useState([])

  const [sorting, setSorting] = useCookieState('sorting', [
    { id: 'followup_on', desc: false },
  ])

  React.useEffect(() => {
    if (dateSortByList.includes(sortByValue)) {
      setDateSortByValue(sortByValue)
    }
  }, [setDateSortByValue, sortByValue])

  React.useEffect(() => {
    const newSorting = []

    if (pinFollowupJobs) newSorting.push({ id: 'followup_on', desc: false })

    newSorting.push({
      id: dateSortByValue,
      desc: dateSortByValue !== 'followup_on',
    })

    setSorting(newSorting)
  }, [dateSortByValue, pinFollowupJobs, setSorting])

  return {
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters,
    pinFollowupJobs,
    setPinFollowupJobs,
    sortByValue,
    setSortByValue,
    selectedFilters,
    setSelectedFilters,
    selectedFiltersRef,
  }
}

const useCookieState = (cookieName, defaultValue) => {
  const [cookies, setCookie] = useCookies([cookieName])
  const cookieValue =
    cookies[cookieName] === undefined ? defaultValue : cookies[cookieName]

  const setCookieValue = React.useCallback(
    (value) => {
      setCookie(cookieName, value, { path: '/job-search/saved_jobs' })
    },
    [cookieName, setCookie],
  )

  return [cookieValue, setCookieValue]
}
