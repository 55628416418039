import React from 'react'
import { LockOpenIcon } from '@heroicons/react/24/outline'
import { useTranslation } from '~/hooks/useTranslation'

const Outcomes = ({ outcomes }) => {
  const { t } = useTranslation('shared')
  return (
    <div className="flex flex-col gap-4 text-base">
      <div className="subtitle">{t('unlock_insights')}</div>
      {outcomes.map((outcome, idx) => (
        <div key={`outcome-${idx}`} className="flex gap-2">
          <LockOpenIcon className="size-5 text-intoo-blue-medium" />
          <div className="text-sm">{outcome}</div>
        </div>
      ))}
    </div>
  )
}
export default Outcomes
