import React from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { useExercise } from '../ExerciseProvider'
import AddAnother from './AddAnother'

const RetirementGoalsPageOne = () => {
  const { formattedExerciseName } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const pageOneTranslations = t('pages.0')

  const sections = [
    {
      header: pageOneTranslations.p1header,
      paragraph: pageOneTranslations.p1paragraph,
    },
    {
      header: pageOneTranslations.p2header,
      paragraph: pageOneTranslations.p2paragraph,
    },
    {
      header: pageOneTranslations.p3header,
      paragraph: pageOneTranslations.p3paragraph,
    },
  ]

  return (
    <div>
      {sections.map((section, index) => (
        <div key={index}>
          <p className="!font-bold">{section.header}</p>
          <p>{section.paragraph}</p>
        </div>
      ))}
      <p>{pageOneTranslations.review_questions}</p>
      <div className="mb-4">
        <p className="!font-bold">
          {pageOneTranslations.things_you_are_good_at}
        </p>
        <AddAnother slug="goodAt" />
      </div>
      <div className="mb-4">
        <p className="!font-bold">{pageOneTranslations.activities_you_tried}</p>
        <AddAnother slug="triedAndEnjoyed" />
      </div>
      <p className="!font-bold">{pageOneTranslations.activities_to_try}</p>
      <AddAnother slug="interestedIn" />
    </div>
  )
}

export default RetirementGoalsPageOne
