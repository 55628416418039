import React, { useState } from 'react'
import { useExercise } from './../ExerciseProvider'
import { useTranslation } from '../../../hooks/useTranslation'

const AddAnother = ({ slug }) => {
  const {
    trackContent,
    exerciseIndex,
    setTrackContent,
    formattedExerciseName,
  } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const pageOneTranslations = t('pages.0')
  const savedItems = trackContent[exerciseIndex]?.[slug] || []
  const [textBoxes, setTextBoxes] = useState(
    savedItems.length > 0 ? savedItems : [''],
  )

  const updateNestedResponse = (section, slug, response) => {
    setTrackContent((prevContent) => {
      const updatedSection = { ...prevContent[section] }
      updatedSection[slug] = response

      return {
        ...prevContent,
        [section]: updatedSection,
      }
    })
  }

  const handleChange = (index, event) => {
    const newTextBoxes = [...textBoxes]
    newTextBoxes[index] = event.target.value
    setTextBoxes(newTextBoxes)
    updateNestedResponse(0, slug, newTextBoxes)
  }

  const addAnotherTextBox = () => {
    const newTextBoxes = [...textBoxes, '']
    setTextBoxes(newTextBoxes)
    updateNestedResponse(0, slug, newTextBoxes)
  }

  const removeTextBox = (index) => {
    const newTextBoxes = textBoxes.filter((_, i) => i !== index)
    setTextBoxes(newTextBoxes)
    updateNestedResponse(0, slug, newTextBoxes)
  }

  return (
    <div className="items space-y-4">
      {textBoxes.map((item, index) => (
        <div key={index} className="flex items-center space-x-2">
          <textarea
            className="flex-1 rounded-[8px] border border-[#D3D3D3] bg-[#F7FAFC] p-3"
            onChange={(e) => handleChange(index, e)}
            value={item}
          />
          <button
            onClick={() => removeTextBox(index)}
            className="font-roboto-light text-sm font-medium text-red-600 hover:text-red-800 focus:underline focus:outline-none"
          >
            {pageOneTranslations.delete}
          </button>
        </div>
      ))}
      <button
        onClick={addAnotherTextBox}
        className="font-roboto-light mt-4 text-sm font-medium text-blue-600 hover:text-blue-800 focus:underline focus:outline-none"
      >
        {pageOneTranslations.add_another}
      </button>
    </div>
  )
}

export default AddAnother
