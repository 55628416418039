import React, { useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { useExercise } from '../ExerciseProvider'

const Checkbox = ({ isChecked, isDisabled, onChange }) => (
  <input
    type="checkbox"
    className="!mb-1 !mr-2 !mt-0 h-4 w-4 rounded border-gray-300 text-blue-600"
    onChange={onChange}
    checked={isChecked}
    disabled={isDisabled}
    onClick={(e) => e.stopPropagation()}
  />
)

const RetirementGoalsPageTwo = () => {
  const {
    formattedExerciseName,
    trackContent,
    updateResponse,
    exerciseIndex,
    getResponse,
  } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const pageTwoTranslations = t('pages.1')

  const sectionOneData = trackContent[0]
    ? Object.values(trackContent[0]).flatMap((values) =>
        values.map((item) => ({ item })),
      )
    : []

  const [selectedCount, setSelectedCount] = useState(0)

  const updateSelectedCount = (isChecked) => {
    setSelectedCount((prevCount) => prevCount + (isChecked ? 1 : -1))
  }

  const handleGroupedChange = (section, key, item, isChecked) => {
    const currentResponse = getResponse(section, key) || []
    const updatedResponse = isChecked
      ? [...currentResponse, item]
      : currentResponse.filter((existingItem) => existingItem !== item)

    updateResponse(section, key, updatedResponse)
  }

  const handleCheckboxChange = (item, e) => {
    const isChecked = e.target.checked
    const currentResponse = getResponse(exerciseIndex, 'topInterests') || []

    if (isChecked && currentResponse.length >= 3) return

    updateSelectedCount(isChecked)
    handleGroupedChange(exerciseIndex, 'topInterests', item.item, isChecked)
  }

  const renderCheckbox = (item) => {
    const currentResponse = getResponse(exerciseIndex, 'topInterests') || []
    const isChecked = currentResponse.includes(item.item)
    const isDisabled = !isChecked && selectedCount >= 3
    return (
      <Checkbox
        isChecked={isChecked}
        isDisabled={isDisabled}
        onChange={(e) => handleCheckboxChange(item, e)}
      />
    )
  }

  const handleDivClick = (section, key, item) => {
    const currentResponse = getResponse(section, key) || []
    const isChecked = currentResponse.includes(item.item)

    if (isChecked) {
      handleGroupedChange(section, key, item.item, false)
      updateSelectedCount(false)
    } else {
      if (currentResponse.length < 3) {
        handleGroupedChange(section, key, item.item, true)
        updateSelectedCount(true)
      }
    }
  }

  return (
    <div>
      <p>{pageTwoTranslations.select_three}</p>
      {sectionOneData.map((item, index) => (
        <div key={index} className="mb-6 font-roboto text-base font-medium">
          <div
            className="flex items-center rounded-[8px] border border-[#D3D3D3] bg-[#F7FAFC] p-3"
            onClick={() => handleDivClick(exerciseIndex, 'topInterests', item)}
          >
            {renderCheckbox(item)}
            <label className="font-roboto-light !mb-1 text-sm font-medium">
              {item.item}
            </label>
          </div>
        </div>
      ))}
    </div>
  )
}

export default RetirementGoalsPageTwo
