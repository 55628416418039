import React, { useState, useEffect } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { useExercise } from '../ExerciseProvider'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../lib/ui/select'

const RetirementGoalsPageThree = () => {
  const {
    formattedExerciseName,
    trackContent,
    updateResponse,
    setContinueButtonDisabled,
  } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const pageThreeTranslations = t('pages.2')

  const selectedInterests = trackContent[1]?.topInterests || []

  const [actionPlans, setActionPlans] = useState(
    selectedInterests.map((interest) => ({
      interest,
      actions: [{ action: '', timeline: '' }],
      barriers: '',
      peopleToShareWith: '',
    })),
  )

  const saveActionPlans = (updatedPlans) => {
    setActionPlans(updatedPlans)
    updateResponse(2, 'actionPlan', updatedPlans)
  }

  const handleFieldChange = (interestIndex, field, value) => {
    const updatedPlans = [...actionPlans]
    updatedPlans[interestIndex][field] = value
    saveActionPlans(updatedPlans)
  }

  const handleActionChange = (interestIndex, actionIndex, field, value) => {
    const updatedPlans = [...actionPlans]
    updatedPlans[interestIndex].actions[actionIndex][field] = value
    saveActionPlans(updatedPlans)
  }

  const addAction = (interestIndex) => {
    const updatedPlans = [...actionPlans]
    updatedPlans[interestIndex].actions.push({ action: '', timeline: '' })
    saveActionPlans(updatedPlans)
  }

  const removeAction = (interestIndex, actionIndex) => {
    const updatedPlans = [...actionPlans]
    updatedPlans[interestIndex].actions.splice(actionIndex, 1)
    saveActionPlans(updatedPlans)
  }

  useEffect(() => {
    const savedPlans = trackContent[2]?.actionPlan || []
    if (savedPlans.length > 0) {
      setActionPlans(savedPlans)
    }

    // Check if every interest has at least one non-empty action
    const allInterestsHaveActions = actionPlans.every((plan) =>
      plan.actions.some((action) => action.action.trim() !== ''),
    )

    // Set the continue button disabled state based on the check
    setContinueButtonDisabled(!allInterestsHaveActions)
  }, [trackContent, actionPlans, setContinueButtonDisabled])

  return (
    <div>
      <h2 className="mb-4">{pageThreeTranslations.retirement_action_plan}</h2>
      {selectedInterests.map((interest, interestIndex) => (
        <div key={interestIndex} className="mb-6">
          <h3 className="mb-2">{interest}</h3>
          <div className="mt-4">
            {actionPlans[interestIndex].actions.map((action, actionIndex) => (
              <div key={actionIndex} className="mb-4">
                <div className="flex items-center space-x-2">
                  <textarea
                    value={action.action}
                    onChange={(e) =>
                      handleActionChange(
                        interestIndex,
                        actionIndex,
                        'action',
                        e.target.value,
                      )
                    }
                    className="mb-2 w-full rounded-[8px] border border-[#D3D3D3] bg-[#F7FAFC] p-3"
                    placeholder={pageThreeTranslations.action_placeholder}
                  />
                  <button
                    onClick={() => removeAction(interestIndex, actionIndex)}
                    className="font-roboto-light text-sm font-medium text-red-600 hover:text-red-800 focus:underline focus:outline-none"
                  >
                    {pageThreeTranslations.delete_action}
                  </button>
                </div>
                <div className="w-64">
                  <Select
                    onValueChange={(value) => {
                      handleActionChange(
                        interestIndex,
                        actionIndex,
                        'timeline',
                        value,
                      )
                    }}
                    value={
                      action.timeline.includes('-') ||
                      action.timeline === 'none'
                        ? 'none'
                        : action.timeline
                    }
                    disabled={
                      action.timeline !== 'none' &&
                      action.timeline.includes('-')
                    }
                  >
                    <SelectTrigger>
                      <SelectValue
                        placeholder={pageThreeTranslations.select_timeline}
                      />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="none">
                        {pageThreeTranslations.select_timeline}
                      </SelectItem>
                      <SelectItem value={pageThreeTranslations.today}>
                        {pageThreeTranslations.today}
                      </SelectItem>
                      <SelectItem value={pageThreeTranslations.this_week}>
                        {pageThreeTranslations.this_week}
                      </SelectItem>
                      <SelectItem value={pageThreeTranslations.this_month}>
                        {pageThreeTranslations.this_month}
                      </SelectItem>
                      <SelectItem value={pageThreeTranslations.this_year}>
                        {pageThreeTranslations.this_year}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <div className="mt-[10px] flex flex-col items-center">
                    <p className="mb-2 text-center">
                      {pageThreeTranslations.choose_specific_data}
                    </p>
                  </div>
                  <input
                    type="date"
                    onChange={(e) =>
                      handleActionChange(
                        interestIndex,
                        actionIndex,
                        'timeline',
                        e.target.value,
                      )
                    }
                    className="w-full rounded border border-gray-300 p-2 text-center disabled:cursor-not-allowed disabled:opacity-50"
                    value={action.timeline.includes('-') ? action.timeline : ''}
                    disabled={
                      !action.timeline.includes('-') &&
                      action.timeline !== '' &&
                      action.timeline !== 'none'
                    }
                  />
                </div>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addAction(interestIndex)}
              className="text-blue-500 underline"
            >
              {pageThreeTranslations.add_action}
            </button>
          </div>
          <div className="mt-4">
            <p className="mb-2 !font-bold">
              {pageThreeTranslations.preventing_getting_started}
            </p>
            <textarea
              value={actionPlans[interestIndex].barriers}
              onChange={(e) =>
                handleFieldChange(interestIndex, 'barriers', e.target.value)
              }
              className="w-full rounded-[8px] border border-[#D3D3D3] bg-[#F7FAFC] p-3"
            />
          </div>
          <div className="mt-4">
            <p className="mb-2 !font-bold">
              {pageThreeTranslations.share_this_interest}
            </p>
            <textarea
              value={actionPlans[interestIndex].peopleToShareWith}
              onChange={(e) =>
                handleFieldChange(
                  interestIndex,
                  'peopleToShareWith',
                  e.target.value,
                )
              }
              className="w-full rounded-[8px] border border-[#D3D3D3] bg-[#F7FAFC] p-3"
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default RetirementGoalsPageThree
