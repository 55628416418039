import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import StartQuiz from '../StartQuiz'
import { TranslationProvider } from '../../shared/TranslationProvider'
import translations from '../locales.json'
import { QuizProvider, useQuiz } from '../QuizProvider'
import { DiscoverYourLearningStyleProvider } from './DiscoverYourLearningStyleProvider'
import Quiz from '../Quiz'
import Questions from './Questions'
import DiscoverYourLearningStyleResults from './DiscoverYourLearningStyleResults'

const DiscoverYourLearningStyle = ({ locale, ...props }) => {
  return (
    <Router>
      <TranslationProvider
        locale={locale || 'en'}
        translations={translations}
        scope={'javascript.components.Quizzes'}
      >
        <QuizProvider {...props}>
          <DiscoverYourLearningStyleProvider>
            <DiscoverYourLearningStyleInner {...props} />
          </DiscoverYourLearningStyleProvider>
        </QuizProvider>
      </TranslationProvider>
    </Router>
  )
}

export default DiscoverYourLearningStyle

const DiscoverYourLearningStyleInner = () => {
  const { documentId, started, showResults } = useQuiz()
  return (
    <div>
      {!started && !showResults && <StartQuiz quizExists={!!documentId} />}
      {started && !showResults && (
        <Quiz>
          <Questions />
        </Quiz>
      )}
      {showResults && <DiscoverYourLearningStyleResults />}
    </div>
  )
}
