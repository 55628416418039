import * as React from 'react'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../lib/ui/form'
import { RadioGroup, RadioGroupItem } from '~/lib/ui/radio-group'
import { useJobSearch } from './JobSearchProvider'

const FilterRadioGroup = ({
  name,
  label,
  description,
  items,
  selectAllLabel,
  showLabel = false,
}) => {
  const {
    form: { control },
  } = useJobSearch()
  const className = showLabel ? 'text-sm sm:text-base !mb-0' : 'sr-only'

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="mr-8 sm:mr-12">
          <div>
            <FormLabel className={className}>{label}</FormLabel>
            <FormDescription className="sr-only">{description}</FormDescription>
          </div>
          <RadioGroup onValueChange={field.onChange} defaultValue={field.value}>
            {items.map((item) => (
              <FormItem
                key={item.value}
                className="flex flex-row items-start space-x-3 space-y-0"
              >
                <FormControl>
                  <RadioGroupItem value={item.value} />
                </FormControl>
                <FormLabel className="!font-normal !leading-none *:text-sm sm:text-base">
                  {item.label}
                </FormLabel>
              </FormItem>
            ))}
            <FormItem className="flex flex-row items-start space-x-3 space-y-0">
              <FormControl>
                <RadioGroupItem value={''} />
              </FormControl>
              <FormLabel className="!font-normal !leading-none *:text-sm sm:text-base">
                {selectAllLabel}
              </FormLabel>
            </FormItem>
          </RadioGroup>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
export default FilterRadioGroup
