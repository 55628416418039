import React from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { useQuiz } from '../QuizProvider'
import { cn } from '../../../lib/utils'
import { PuzzlePieceIcon, ClockIcon } from '@heroicons/react/24/outline'

const Sections = ({ numQuestions }) => {
  const { quizTime } = useQuiz()
  const { t } = useTranslation('')
  return (
    <div
      className={cn(
        `flex flex-col items-center border-[#e5e5e5] bg-white px-4 pt-8 md:flex-row md:divide-x md:divide-[#e5e5e5]`,
      )}
    >
      <Section className="px-6 text-center" section={'time'}>
        {t('shared.minutes', { quizTime })}
      </Section>
      <Section className="px-6 text-center" section={'questions'}>
        {t('shared.questions', { numQuestions })}
      </Section>
    </div>
  )
}
export default Sections

const Section = ({ section, className, children }) => {
  const iconClasses = cn('size-5 text-intoo-blue-medium')
  const icons = {
    time: <ClockIcon className={iconClasses} />,
    questions: <PuzzlePieceIcon className={iconClasses} />,
  }

  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center gap-2 text-intoo-blue-medium',
        className,
      )}
    >
      <div>{icons[section]}</div>
      <div className="text-base font-medium">{children}</div>
    </div>
  )
}
